<template>
  <v-container style="max-width:1200px;">
    <v-row justify="center">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        :class="{ 'pa-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-card flat style="border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 px-4 pl-2"
            style="background-color:transparent;color:#AD1457;border-bottom: 2px solid #AD1457;"
          >
            <v-btn icon color="#AD1457" @click="goBack" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Đăng ký thuê phòng
          </v-card-title>

          <v-card-text
            class="py-2 pt-2"
            :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
          >
            <!-- Error Message -->
            <div v-if="errorMessage" style="color:red;" class="py-1">
              {{ errorMessage }}
            </div>

            <!-- Select a Room -->
            <div>
              <div
                class="pl-1"
                style="font-size:11px;font-weight:500;line-height:1.5;color:#777777;"
              >
                Chọn phòng:
              </div>
              <room-property-picker
                large
                v-model="roomsSelected"
                type="phong-hop"
              />
              <!-- Mô tả phòng -->
              <div v-if="roomsSelected.length > 0 && roomDetail">
                <!-- image -->
                <!-- list of image -->
                <div class="pt-2">
                  <template v-for="(img, imgIdx) in roomDetail.images">
                    <div
                      class="pa-1 mr-2 mb-2"
                      :key="'img' + imgIdx"
                      style="vertical-align: top;position:relative;display: inline-block;min-height: 80px;max-height: 80px;min-width: 130px;max-width: 130px;border:1px solid #DDDDDD;"
                    >
                      <div
                        style="width:100%;height:70px;z-index:1; overflow-y:hidden;cursor:pointer;"
                        @click.stop="showImageFullScreen(img)"
                      >
                        <img
                          :src="img"
                          style="object-fit:cover;width:100%;height:100%;"
                        />
                      </div>
                    </div>
                  </template>
                </div>

                <div class="userrent-description-room">
                  <v-textarea
                    flat
                    dense
                    :value="roomDetail.description"
                    label="Mô tả phòng họp:"
                    color="#ccc"
                    outlined
                    filled
                    :rows="5"
                    no-resize
                    hide-details
                    readonly
                  ></v-textarea>
                </div>
              </div>
            </div>

            <!-- Select A Time Range -->
            <div class="mt-2">
              <div
                class="pl-1"
                style="font-size:11px;font-weight:500;line-height:1.5;color:#777777;"
              >
                Chọn thời gian:
              </div>
              <!-- startDate -->
              <div
                class="py-1 mr-4"
                :class="{ 'rent-inline-block': $vuetify.breakpoint.mdAndUp }"
              >
                <div style="display:inline-block;min-width:80px;">Bắt đầu:</div>
                <div style="display:inline-block;">
                  <vc-date-picker
                    v-model="startDate"
                    mode="dateTime"
                    is24hr
                    :minute-increment="15"
                    :min-date="new Date()"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        style="background-color:#F0F0F0;"
                        class="px-2 py-1 border rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
              </div>
              <!-- endDate -->
              <div
                class="py-1"
                :class="{ 'rent-inline-block': $vuetify.breakpoint.mdAndUp }"
              >
                <div style="display:inline-block;min-width:80px;">
                  Kết thúc:
                </div>
                <div style="display:inline-block;">
                  <vc-date-picker
                    v-model="endDate"
                    mode="dateTime"
                    is24hr
                    :minute-increment="15"
                    :min-date="new Date()"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        style="background-color:#F0F0F0;"
                        class="px-2 py-1 border rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
              </div>
              <!-- Số giờ thuê -->
              <div class="py-1">
                Số giờ thuê:
                <span style="font-size:16px;" class="ml-2">
                  <b>{{ numOfHours }}</b></span
                >
                <span v-if="numOfHours <= 0" style="color:red;" class="ml-2">
                  Thời gian không hợp lệ!
                </span>
                <span v-if="isTimeInterfere" style="color:red;" class="ml-2">
                  Thời gian không hợp lệ! Đã có người khác chọn khung giờ này.
                </span>
              </div>
            </div>

            <!-- Inviter -->
            <div class="mt-2">
              <!-- <div
                class="pl-1 mb-1"
                style="font-size:11px;font-weight:500;line-height:1.5;color:#777777;"
              >
               Chọn khách mời:
              </div> -->
              <inviter-user @inviter-done="approveInviter" />
            </div>

            <!-- Description -->
            <v-textarea
              class="mt-2"
              v-model="session.description"
              label="Nội dung"
              color="black"
              outlined
              hide-details
              dense
              auto-grow
              :row="3"
            ></v-textarea>
          </v-card-text>

          <v-card-actions class="px-4">
            <v-spacer />
            <v-btn
              :disabled="
                numOfHours <= 0 ||
                  !roomsSelected ||
                  roomsSelected.length <= 0 ||
                  isTimeInterfere
              "
              color="#AD1457"
              class="text-none px-4"
              @click="createASession"
              style="color:white;"
            >
              Đăng ký
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card style="border-radius:8px;min-height:320px;">
          <v-card-title
            class="py-1 px-2 pl-4 mb-1"
            dark
            style="color:teal;border-bottom:1px solid teal;font-size:14px;"
          >
            Các lượt thuê đang có
            <v-spacer />
            <v-btn
              class="ma-2 pa-1 px-2"
              outlined
              @click="showCalendar = !showCalendar"
              :color="showCalendar ? '#AD1457' : 'grey'"
              min-width="0"
            >
              <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-row>
              <v-col
                class="pt-0"
                :class="{
                  'd-none': !allowShowCalendar,
                }"
                cols="12"
                md="12"
              >
                <mcv-calendar :data="displayCalendar"
                  :allowShowEvent="false"
                
                ></mcv-calendar>
              </v-col>
              <v-col
                :class="{
                  'd-none': !allowShowTable,
                }"
                cols="12"
                md="12"
              >
                <v-data-table
                  flat
                  :headers="headers"
                  :items="sessionsAfterFilter"
                  :loading="loadingSession"
                  loading-text="Loading... Please wait"
                  no-data-text="Chưa có lượt thuê nào"
                  item-key="_id"
                  :mobile-breakpoint="0"
                  :items-per-page="pageSize"
                  single-select
                  dense
                  class="elevation-0"
                  :page.sync="depsPage"
                  @page-count="pageCount = $event"
                  hide-default-footer
                >
                  <template v-slot:item.start_time="{ item }">
                    {{ $moment(item.start_time).format("HH:mm DD/MM/YY") }}
                  </template>
                  <template v-slot:item.end_time="{ item }">
                    {{ $moment(item.end_time).format("HH:mm DD/MM/YY") }}
                  </template>
                  <!-- <template v-slot:item.created_at="{ item }">
                        {{ $moment(item.created_at).format("HH:mm DD/MM/YYYY") }}
                     </template> -->
                  <template v-slot:item.status="{ item }">
                    <div
                      style="font-size:12px;font-weight:600;"
                      :style="{ color: getStatusColor(item.status) }"
                    >
                      {{ getStatusName(item.status) }}
                    </div>
                  </template>
                </v-data-table>
                <div v-if="pageCount > 1" class="text-center pt-2">
                  <v-pagination
                    v-model="depsPage"
                    :length="pageCount"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <show-full-image
      :show="showFullImage"
      :image="imgFullScreen"
      @close-dialog-show-fullimage="showFullImage = false"
    ></show-full-image>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import mixins_room from "@/mixins/Rooms/index.js";

export default {
  watch: {
    roomsSelected: function() {
      if (this.roomsSelected.length > 0) {
        this.fetchAllSessions([this.roomsSelected[0].id]);
        this.fetchRoomsById(this.roomsSelected[0].id);
      }
    },
    startDate: function() {
      this.endDate = this.$moment(this.startDate)
        .add(1, "hours")
        .toDate();
    },
  },
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
    numOfHours: function() {
      return (
        this.$moment(this.endDate).diff(
          this.$moment(this.startDate),
          "minutes"
        ) / 60
      );
    },
    sessionsAfterFilter() {
      let self = this;
      let result = self.sessions;

      // Filter of Time
      if (result.length > 0 && self.startDate > 0) {
        let filter = [];
        let startThres = this.$moment(this.startDate).startOf("day");
        let endThres = this.$moment(this.endDate).endOf("day");
        filter = result.filter(
          (s) =>
            (startThres.valueOf() <= this.$moment(s.start_time).valueOf() &&
              this.$moment(s.start_time).valueOf() <= endThres.valueOf()) ||
            (startThres.valueOf() <= this.$moment(s.end_time).valueOf() &&
              this.$moment(s.end_time).valueOf() <= endThres.valueOf()) ||
            (startThres.valueOf() <= this.$moment(s.end_time).valueOf() &&
              endThres.valueOf() >= this.$moment(s.start_time).valueOf())
        );
        result = filter;
      }

      // Filter of Status
      if (result.length > 0) {
        let filter = [];
        filter = result.filter((s) =>
          ["WAIT_FOR_APPROVE", "APPROVED"].includes(s.status)
        );
        result = filter;
      }

      //Filter of Properties
      // if (result.length > 0) {
      //    let filter = [];
      //    filter = result.filter(
      //       (s) =>
      //          self.propertiesFilterArr.filter((i) => i.id == s.properties_id)
      //          .length > 0
      //    );
      //    result = filter;
      // }

      return result;
    },
    isTimeInterfere() {
      let interfere = false;
      let longStartDate = this.startDate.getTime();
      let longEndDate = this.endDate.getTime();
      if (this.sessionsAfterFilter) {
        this.sessionsAfterFilter.forEach((session) => {
          let longSessionStart = this.$moment(session.start_time)
            .toDate()
            .getTime();
          let longSessionEnd = this.$moment(session.end_time)
            .toDate()
            .getTime();
          if (
            longSessionStart < longStartDate &&
            longStartDate < longSessionEnd
          ) {
            interfere = true;
          }
          if (longSessionStart < longEndDate && longEndDate < longSessionEnd) {
            interfere = true;
          }

          if (
            longStartDate < longSessionStart &&
            longSessionStart < longEndDate
          ) {
            interfere = true;
          }

          if (longStartDate < longSessionEnd && longSessionEnd < longEndDate) {
            interfere = true;
          }

          if (
            longStartDate == longSessionStart &&
            longEndDate == longSessionEnd
          ) {
            interfere = true;
          }

          // if(longStartDate<longSessionStart && longEndDate>longSessionEnd){
          //   interfere = true
          // }

          // if(longStartDate>longSessionStart && longEndDate<longSessionEnd){
          //   interfere = true
          // }
        });
      }
      return interfere;
    },
    allowShowTable() {
      return !this.showCalendar;
    },
    allowShowCalendar() {
      return this.showCalendar;
    },
    displayCalendar() {
      let result = [];
      let sessionsAfFilter = this.sessionsAfterFilter;
      if (!sessionsAfFilter || sessionsAfFilter.length <= 0) return [];
      sessionsAfFilter.forEach((item) => {
        //Set Color
        let color = "#263238";
        if (item.properties && item.properties.length > 0) {
          item.properties.map((i) => {
            let r = this.properties.find((e) => e._id == i._id);
            if (r) color = r.color;
          });
        }

        //Set Time
        let startTime = this.$moment(item.start_time).format(
          "YYYY-MM-DD HH:mm"
        );
        let endTime = this.$moment(item.end_time).format("YYYY-MM-DD HH:mm");
        // if (
        //   this.$moment(item.start_time).valueOf() <
        //   this.$moment(this.range.start).valueOf()
        // ) {
        //   startTime = this.$moment(this.range.start).format("YYYY-MM-DD HH:mm");
        // }
        // if (
        //   this.$moment(item.end_time).valueOf() >
        //   this.$moment(this.range.end).valueOf()
        // ) {
        //   endTime = this.$moment(this.range.end).format("YYYY-MM-DD HH:mm");
        // }

        let content =
          item.properties && item.properties[0].name
            ? item.properties[0].name
            : "-";
        content = content + " - " + item.description;

        result.push({
          data: item,
          name: content,
          start: startTime,
          end: endTime,
          color: color,
          timed: true,
        });
      });

      return result;
    },
  },
  mixins: [mixins_room],
  data() {
    return {
      session: {
        description: "",
      },
      roomsSelected: [],
      roomDetail: null,
      showFullImage: false,
      imgFullScreen: "",
      errorMessage: "",
      users: [],
      sessions: [],
      properties: [],
      pageSize: 20,
      pageCount: 0,
      loadingSession: false,
      depsPage: 1,
      startDate: this.$moment()
        .startOf("day")
        .add(1, "day")
        .add(9, "hours")
        .toDate(),
      endDate: this.$moment()
        .startOf("day")
        .add(1, "day")
        .add(10, "hours")
        .toDate(),
      headers: [
        {
          text: "Người Thuê",
          align: "start",
          sortable: true,
          value: "user_name",
        },
        {
          text: "Thời Gian Bắt Đầu",
          align: "start",
          sortable: true,
          value: "start_time",
        },
        {
          text: "Thời Gian Kết Thúc",
          align: "start",
          sortable: true,
          value: "end_time",
        },
        {
          text: "Trạng Thái",
          align: "center",
          sortable: true,
          value: "status",
          width: 100,
        },
      ],
      statusList: [
        {
          selected: true,
          code: "WAIT_FOR_APPROVE",
          label: "Chờ duyệt",
          color: "orange",
        },
        { selected: true, code: "APPROVED", label: "Đã duyệt", color: "green" },
        {
          selected: false,
          code: "REJECTED",
          label: "Đã từ chối",
          color: "red",
        },
        {
          selected: false,
          code: "CANCELLED",
          label: "Đã hủy",
          color: "#888888",
        },
      ],
      showCalendar: false,
      lastClickBtn: null
    };
  },
  methods: {
    approveInviter(result) {
      this.session.inviters_id = result;
    },
    goBack() {
      this.$router.go(-1);
    },
    getStatusName(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.label;
      return "";
    },
    getStatusColor(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.color;
      return "black";
    },
    processAfterFetchData() {
      if (this.sessions && this.sessions.length > 0) {
        this.sessions.map((item) => {
          //Users
          if (!this.users.find((i) => i._id == item.user_id)) {
            item.user_name = "";
          } else {
            item.user_name = this.users.find(
              (i) => i._id == item.user_id
            ).fullname;
          }
        });
      }
    },
    createASession() {
      if (!this.roomsSelected || this.roomsSelected.length <= 0) {
        alert("Vui lòng chọn phòng!");
        return;
      }

      if(!this.lastClickBtn){
        this.lastClickBtn = Date.now()
      }
      else if(Date.now() - this.lastClickBtn < 2000){
        this.lastClickBtn = Date.now();
        return;
      }

      let self = this;
      self.session.code = "RENT-ROOM-" + new Date().getTime();
      self.session.start_time = this.startDate;
      self.session.end_time = this.endDate;
      self.session.user_id = this.userId;
      self.session.department_id = "";
      self.session.properties_id = self.roomsSelected.map((p) => {
        return p.id;
      });

      this.axios
        .post(this.$root.apiAssetMana + "/sessions", self.session, {
          headers: { "x-auth": self.token },
        })
        .then((res) => {
          if (res.data.status === "OK") {
            alert("Đăng ký thành công!");
            self.$router.push({ path: "/rooms-history" });
          } else {
            alert("Lỗi: " + res.data.message);
          }
        })
        .catch((err) => {
          alert("Lỗi: " + err.message);
        });
    },
    fetchAllUsers() {
      let self = this;
      this.axios
        .get(self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId)
        .then((res) => {
          if (res.data.status == "OK") {
            self.users = res.data.content.items;
          }
        });
    },
    fetchAllSessions(properties_id) {
      if (!properties_id || properties_id.length <= 0) return;
      let self = this;
      this.loadingSession = true;
      this.axios
        .get(
          self.$root.apiAssetMana +
            "/sessions?type=rent&limit=10000&properties_id=" +
            properties_id.join(";")
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.sessions = res.data.content.items;
          }
        })
        .finally(() => {
          self.loadingSession = false;
          self.processAfterFetchData();
        });
    },
    fetchRoomsById(room_id) {
      if (!room_id || room_id.length <= 0) return (this.roomDetail = null);
      let self = this;
      self.axios
        .get(self.$root.apiAssetMana + "/properties/" + room_id)
        .then((res) => {
          if (res.data.status == "OK") {
            if (!res.data.content.type || res.data.content.type != "phong-hop")
              return (this.roomDetail = null);
            self.roomDetail = res.data.content;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showImageFullScreen(link) {
      this.showFullImage = true;
      this.imgFullScreen = link;
    },
    fetchAllRooms() {
      let self = this;
      self.loading = true;
      let paramsProperties = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true,
        enable: true,
        type: "phong-hop",
        limit: 10000,
      };

      self.axios
        .get(self.$root.apiAssetMana + "/properties", {
          headers: { "x-auth": this.token },
          params: paramsProperties,
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.properties = res.data.content.items;

            //Set Color
            self.properties.forEach((room, idx) => {
              if (self.roomsColorArr[idx]) {
                room.color = self.roomsColorArr[idx];
              } else {
                room.color = "#263238";
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchAllUsers();
    this.fetchAllRooms();
  },
};
</script>

<style>
.rent-inline-block {
  display: inline-block;
}
.userrent-description-room textarea {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  line-height: 1.35;
  font-size: 14px;
  color: #8c8c8c !important;
}
</style>
